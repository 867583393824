import React from "react";
import Card from "./Card";
import Data from "./EventsDatabase";
import { useLocation } from "react-router-dom";

import "swiper/swiper-bundle.min.css";

export default function Trainings() {
    const styles3 = {
        backgroundImage: `url(https://i.imgur.com/3aQRrj6.jpg)`,
        backgroundPosition: "top",
        backgroundSize: "100%",
        backgroundRepeat: "repeat",
        width: "100%",
        height: "100%",
    };

    const location = useLocation();

    const dataToDisplay = location.pathname === "/projects" ? Data : Data.slice(0, 1);

    return (
        <div
            style={styles3}
            className={`${
                location.pathname === "/projects" ? "pt-40" : "py-20"
            } border-t-2 bg-opacity-5 border-black mx-auto`}
        >
            <div className="grid text-center gap-4 items-center justify-center">
                <h1 className="text-center text-7xl">Upskilling Programs</h1>
                {location.pathname !== "/projects" && (
                    <a
                        className="font-Inter underline hover:text-blue-700 transition-all"
                        href="/projects"
                        style={{ cursor: "pointer" }}
                    >
                        (Click to see more upskilling programs)
                    </a>
                )}
            </div>
            <div className="pb-10">
                {dataToDisplay.map((item) => (
                    <Card
                        key={item.id}
                        img={item.img}
                        imgCard1={item.imgCard1}
                        imgCard2={item.imgCard2}
                        imgCard3={item.imgCard3}
                        title={item.title}
                        location={item.location}
                        year={item.year}
                        lesson={item.lesson}
                    />
                ))}
            </div>
        </div>
    );
}
