import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import Footer from "./Footer";

const youtubeLinks = [
  // "https://youtu.be/GFdjFIfyaAk?si=8epT2AAkLSQsLqNy", Graduation video
  "https://youtu.be/RDCJ78KFjRY",
  "https://youtu.be/SQDOZwvy8Rs",
  "https://youtu.be/eQPKJgZRDn8",
  "https://youtu.be/C-peqQX6JOc",
  "https://youtu.be/gDGXjlhyFQ8",
  "https://youtu.be/4XT3ndLnvxA",
  "https://youtu.be/FQqalctkQWY",
  "https://youtu.be/npKfzgHOvLM",
  "https://youtu.be/q192RB4J3P8",
  "https://youtu.be/UOjmlb8S0T4",
  "https://youtu.be/HISpsoK-6sE",
  "https://youtu.be/GR4yg25halo?si=9kfCZq2HOGWOfHyg",
];

export default function Galleries() {
  const styles3 = {
    backgroundImage: `url(https://i.imgur.com/3aQRrj6.jpg)`,
    backgroundPosition: "top",
    backgroundSize: "100%",
    backgroundRepeat: "repeat",
    width: "100%",
    height: "100%",
  };

  const [activeIndex, setActiveIndex] = React.useState(0);

  return (
    <div
      style={styles3}
      className="pt-40 border-t-2 bg-opacity-5 border-black mx-auto"
    >
      <div className="text-3xl sm:text-7xl mx-auto font-Inter font-bold items-center text-white flex justify-center">
        <h1 className="text-black text-center sm:pb-0 flex max-sm:text-center font-dancing">
          Video Gallery
        </h1>
      </div>

      <div className="mx-auto items-center py-20 lg:px-40 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {youtubeLinks.map((link, index) => (
          <div key={`youtubeLink_${index}`} className="w-full h-[50vh] sm:h-[70vh] max-h-[1000px]">
            <iframe
              title="video"
              className="w-full h-full"
              src={`https://www.youtube.com/embed/${
                link.split("https://youtu.be/")[1]
              }`}
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
}
