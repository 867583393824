import React from "react";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";
import footerwaterfall from "./imgs/footerwaterfall.jpg";
import guyanaflogo from "./imgs/guyanaflogo.png";
import { Link } from "react-router-dom";

export default function Footer() {
  const styles2 = {
    backgroundImage: `url(${footerwaterfall})`,
    backgroundPosition: "top",
    backgroundSize: "100% 120%",
    backgroundRepeat: "no-repeat",
  };
  return (
    <div className=" h-fit">
      <footer
        style={styles2}
        className="border-t-2 font-Inter border-lime-500"
      >
        <div className="px-4 mx-auto overflow-hidden sm:px-6 lg:px-8">
          <nav className="flex justify-center -mx-5 pt-2">
            <div className="px-5 py-2">
              <Link to="/about">
              <p className="text-xs sm:text-base 3840:text-3xl leading-6 text-white hover:underline underline-offset-4">
                  About
                </p>
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link to="/news">
              <p className="text-xs sm:text-base 3840:text-3xl leading-6 text-white hover:underline underline-offset-4">
                  News
                </p>
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link to="/our-team">
              <p className="text-xs sm:text-base 3840:text-3xl leading-6 text-white hover:underline underline-offset-4">
                  Team
                </p>
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link to="/contact">
              <p className="text-xs sm:text-base 3840:text-3xl leading-6 text-white hover:underline underline-offset-4">
                  Contact
                </p>
              </Link>
            </div>
          </nav>
          <div className="flex shadow-2xl drop-shadow-2xl bg-gradient-to-r from-black to-slate-700 rounded-3xl h-fit items-center w-fit p-3 mx-auto text-3xl justify-center mt-2 space-x-6">
            <a
              href="https://www.facebook.com/theguyanafoundation/"
              target="_blank"
              rel="noreferrer"
              className="text-gray-400"
            >
              <span className=" text-xl text-blue-600 hover:text-blue-800">
                <FaFacebook />
              </span>
            </a>
            <a
              href="https://www.instagram.com/guyanafoundation/"
              target="_blank"
              className="text-gray-400 hover:text-white"
              rel="noreferrer"
            >
              <span className="text-xl text-pink-600 hover:text-pink-800">
                <FaInstagram />
              </span>
            </a>
            <a
              href="https://twitter.com/GYFoundation"
              target="_blank"
              className="text-gray-400 hover:text-white"
              rel="noreferrer"
            >
              <span className=" text-xl text-cyan-500 hover:text-cyan-700">
                <FaTwitter />
              </span>
            </a>
          </div>
          <div className="p ve flex pb-2 max-sm:justify-items-center justify-center gap-4 sm:gap-2 items-center grid-flow-row">
            <div>
              <img
                alt="guyanaLogo"
                src={guyanaflogo}
                className="w-10 sm:w-20 grid"
              />
            </div>
            <div>
              <p className="text-xs sm:text-2xl whitespace-nowrap 3840:text-4xl font-Inter tracking-tight font-medium leading-6 text-center text-black">
                © 2025 Guyana Foundation
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
