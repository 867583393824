import React from "react";
import VillageRenewal2 from "./VillageRenewal2";
import Refugee from "./Refugee";
import Counseling from "./Counseling";
import Footer from "./Footer";
import Trainings from "./Trainings";

export default function Projects() {
  return (
    <main className=" font-dancing">
      <Trainings />
      <VillageRenewal2 />
      <Refugee />
      <Counseling />
      <Footer />
    </main>
  );
}
