import React, { lazy, Suspense } from "react"; // Import startTransition
import { Routes, Route } from "react-router-dom";
import "./components/CSSTransition.css";
import Navbar from "./components/Navbar";
import ScrollToTopButton from "./components/ScrollToTopButton";
import Galleries from "./components/Galleries";
import Projects from "./components/Projects";
import Trainings from "./components/Trainings";

const About = lazy(() => import("./components/About"));
const News = lazy(() => import("./components/News"));
const Contact = lazy(() => import("./components/Contact"));
const OurTeam = lazy(() => import("./components/OurTeam"));
const Catering = lazy(() => import("./components/Classes/Catering"));
const Sewing = lazy(() => import("./components/Classes/Sewing"));
const IT = lazy(() => import("./components/Classes/IT"));
const Electrical = lazy(() => import("./components/Classes/Electrical"));
const Cosmetology = lazy(() => import("./components/Classes/Cosmetology"));
const FabricDesign = lazy(() => import("./components/Classes/FabricDesign"));
const Crafting = lazy(() => import("./components/Classes/Crafting"));
const EventPlanning = lazy(() => import("./components/Classes/EventPlanning"));
const ScrollToTop = lazy(() => import("./components/ScrollToTop"));
const Main = lazy(() => import("./components/Main"));
const LoadingPage = lazy(() => import("./components/LoadingPage"));

function App() {
  return (
    <div>
      <Navbar />
      <Suspense fallback={<LoadingPage />}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/news" element={<News />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/galleries/catering" element={<Catering />} />
          <Route path="/galleries/sewing" element={<Sewing />} />
          <Route path="/galleries/it" element={<IT />} />
          <Route path="/galleries/electrical" element={<Electrical />} />
          <Route path="/galleries/cosmetology" element={<Cosmetology />} />
          <Route path="/galleries/fabric-design" element={<FabricDesign />} />
          <Route path="/galleries/crafting" element={<Crafting />} />
          <Route path="/galleries/event-planning" element={<EventPlanning />} />
          <Route path="/galleries" element={<Galleries />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/trainings" element={<Trainings />} />

        </Routes>
      </Suspense>
      <ScrollToTop />
      <ScrollToTopButton />
    </div>
  );
}

export default App;
